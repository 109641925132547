import { type SanityDividerPhotoModule } from '@data/sanity/queries/types/modules'

import Photo from '@components/photo'

type DividerPhotoProps = Pick<SanityDividerPhotoModule, 'photo'>

const DividerPhoto = ({ photo }: DividerPhotoProps) => (
  <div>
    <Photo image={photo} className="w-full" imageClassName="w-full h-auto" />
  </div>
)

export default DividerPhoto
